import { useEffect, useState, useRef } from 'react';
import './Home.scss';
import siete from '../assets/siete.svg';
import Products from '../components/Products';
import Reservation from '../components/Reservation';
const Home = ({ categories }) => {
	const [selected, setSelected] = useState(null);
	const handleCategoryChange = (id) => {
		setSelected(categories.find((c) => parseInt(c.id) === parseInt(id)));
	};

	if (!categories) {
		return null;
	}

	useEffect(() => {
		if (categories) {
			const initialCategory = categories.find((c) => c.name.startsWith('Ramyun'));
			if (initialCategory) {
				setSelected(initialCategory);
			}
		}
	}, [categories]);

	return (
		<main className='home'>
			<img src={siete} style={{ width: '200px', height: 'auto' }} titple='Siete Olas' />
			<h1>Bienvenidos a Siete Olas!</h1>
			<div className='info'>
				<p>Somos la primera y mayor tienda de productos importados de Corea en Santa Marta.</p>
				<p>Tenemos gran variedad de productos como Ramen, Cup Ramen, Dulces, Bebidas con y sin alcohol.</p>
				<p>Explora nuestro catálogo de productos aquí debajo y realiza tu pedido por Instragram o WhatsApp.</p>
			</div>
			<hr />
			<br />
			<ul className='categories'>
				{categories.map((category, i) => (
					<li
						onClick={() => handleCategoryChange(category.id)}
						key={i}
						className={selected?.id === category.id ? 'selected' : ''}
					>
						{category.name}
					</li>
				))}
			</ul>
			{selected && <Products products={selected.products} key={selected.id} />}
		</main>
	);
};

export default Home;
