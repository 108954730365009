import { useState } from 'react';
import './Reservation.scss';

const Reservation = () => {
	const [open, setOpen] = useState(false);
	const toggleModal = () => {
		setOpen(!open);
	};

	return (
		<div id='reservation'>
			<button onClick={toggleModal}>Reservar</button>
			<div className={`modal ${open ? 'open' : ''}`} onClick={toggleModal}>
				<div className='modal-content reservation' onClick={(e) => e.stopPropagation()}>
					<div className='modal-header'>
						<span className='close' onClick={toggleModal}>
							&times;
						</span>
						<h2>Reserva</h2>
					</div>
					<div className='modal-body'>
						<iframe
							title='Reservas'
							src='https://admin.posfacil.co/book?customer=2cfb5490982a71e87c745d0592a5dafccf8a0f62'
							width={'100%'}
							height={'500px'}
							frameBorder={'0'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Reservation;
